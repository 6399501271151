
<footer class="page-footer font-small back_footer" style="padding-top: 1px;">

  <div class="container text-center text-md-left mt-5">
    <div class="row" style="border-bottom: 1px solid white">

      <div class="col-md-4 col-lg-4 col-xl-3 mb-4" style="text-align: initial;">

        <label class=" footer_lbl">{{util.app_name}} </label>

        <label *ngIf="util.web_footer">
          {{util.web_footer}}
        </label>

        
        <div class="flex_box" (click)="goToTerms()">
          <a class="">{{util.translate('Terms & Conditions')}}</a>
        </div>
        <div class="flex_box" (click)="goToRefund()">
          <a class="">{{util.translate('Refund & Cancellation')}}</a>
        </div>
        <div class="flex_box" (click)="goToPrivacy()">
          <a class="">{{util.translate('Privacy Policy')}}</a>
        </div>

         <br>
          <a [href]="util.google_playstore" target="_blank" rel="noopener noreferrer">
            <img src="assets/imgs/android.png" class="app_img hoverable" width="200" height="65">
          </a> 
          <br/><br/>
          <a [href]="util.apple_appstore" target="_blank" rel="noopener noreferrer">
            <img src="assets/imgs/ios.png" class="app_img hoverable" width="200" height="65">
          </a>
         
          
          

          

      </div>

      <div class="col-md-4 col-lg-4 col-xl-2 mx-auto mb-4" style="text-align: initial;">

        <label class=" footer_lbl">{{util.translate('Important Links')}}</label>

        <div class="flex_box" (click)="goToAbout()">
          <a class="">{{util.translate('About')}}</a>
        </div>
        <div class="flex_box"> <a href="https://restaurants.restrocafe.com/" target="_restaurants" >Restaurant Login</a> </div>
        <div class="flex_box" (click)="blogs()">
          <a class="">{{util.translate('The Blogs')}}</a>
        </div>
        <div class="flex_box" (click)="goToContact()">
          <a class="">{{util.translate('Contact us')}}</a>
        </div>
        <div class="flex_box" (click)="goToFaqs()">
          <a class="">{{util.translate('FAQ')}}</a>
        </div>
        <div class="flex_box" (click)="goToNotice()">
          <a class="">{{util.translate('Legal Mentions')}}</a>
        </div>
        <div class="flex_box" (click)="goToCookies()">
          <a class="">{{util.translate('Cookie Policy')}}</a>
        </div>
      </div>

      <div class="col-md-4 col-lg-4 col-xl-3 mx-auto mb-md-0 mb-4" style="text-align: initial;" *ngIf="util.general">
        <label class=" footer_lbl">{{util.translate('Contact')}}</label>

        <div class="flex_box">
          <a>
            {{util.general.city}} - {{util.general.country}} - {{util.general.zip}}
          </a>
        </div>

        <div class="flex_box">
          <a>{{util.general.mobile}} </a>
        </div>

        <div class="flex_box">
          <a>{{util.general.email}} </a>
        </div>
        <br>
        <label class=" footer_lbl">{{util.translate('For Restaurant On-board')}}</label>
        <div class="flex_box">
          <a href="https://wa.me/919416523761?text=Hello" target="_waps" ><span><mdb-icon fab icon="whatsapp"></mdb-icon></span> +919416523761</a> 
        </div>
        <br>
        <a href="https://play.google.com/store/apps/details?id=restaurants.restrocafe.com" target="_blank" rel="noopener noreferrer">
          <img src="assets/imgs/partner-owner.png" class="app_img hoverable" width="200" height="65">
        </a> 
        <br/>


      </div>
    </div>

  </div>

  <div class="footer-copyright text-center py-3" style="background: rgba(0,0,0,0.5); text-align: initial;">
    <div class="container">
      <label>© {{getCopyright()}} {{util.translate('Copyright')}}:<a [href]="util.websiteURL">
          {{util.websiteName}}</a></label>
      <div>
        <a [href]="util.facebook_link" target="_blank" rel="noopener noreferrer">
          <mdb-icon fab icon="facebook-f"></mdb-icon>
        </a>
        <a [href]="util.instagram_link" target="_blank" rel="noopener noreferrer">
          <mdb-icon fab icon="instagram"></mdb-icon>
        </a>
        <a href="https://www.youtube.com/channel/UCUGk4N6mk2A6fH5PylZ7OqA/featured" target="_ytb" rel="noopener noreferrer">
          <mdb-icon fab icon="youtube"></mdb-icon>
        </a>
        <!-- <a [href]="util.twitter_link" target="_blank" rel="noopener noreferrer">
          <mdb-icon fab icon="twitter"></mdb-icon>
        </a> -->
        

      </div>
    </div>
  </div>

</footer>
<!-- Footer -->
