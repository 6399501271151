export const environment = {
  production: false,
  baseURL: 'https://api.restrocafe.com/index.php/',
  mediaURL: 'https://api.restrocafe.com/uploads/',
  bannerURL: 'https://restrocafe.com/uploads/',

  onesignal: {
    appId: 'dfc4a9e6-c2dd-4376-be2b-3b1e2e34488c',
    googleProjectNumber: '424537586952',
    restKey: 'YThmOTEwYzYtNzkyOC00NmFjLThhMzUtMWExNDFmOTk1MTE0'
  },
  authToken: 'aWigsecret_ofAtleast32CharactERS',
  app_name: 'restrocafe.com',
  websiteURL: 'https://restrocafe.com',
  websiteName: 'Restrocafe',
  default_country_code: '91'
};
